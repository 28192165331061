import axios from 'axios';
import React, { Component } from 'react';

import I18n from '../../core/i18n';
import progressBarQuery from '../queries/progressBarQuery';
import XpPointsCounterHeader from './XpPointsCounterHeader';

// fix CSRF error raised by Rails
if (document.querySelector('meta[name="csrf-token"]')) {
  axios.defaults.headers.common = {
    'X-CSRF-TOKEN': document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content'),
    'X-Requested-With': 'XMLHttpRequest',
  };
}

const arrowRight = require('../images/icons/arrow-right.svg');

export default class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = () => {
    this.fetchProgressData();
  };

  fetchProgressData = () => {
    let challengeData;
    let challengeQuestions;

    const userInChallenge =
      window.location.pathname.split('/')[1] === 'challenge_questions';

    if (userInChallenge) {
      const url = window.location.search;
      let urlData = '';
      if (url) {
        const urlParam = new URLSearchParams(url);
        urlData = urlParam.get('from');
      }
      const challengeQuestionId = parseInt(
        window.location.pathname.split('/challenge_questions/')[1],
        10
      );

      const query = progressBarQuery(challengeQuestionId);

      axios
        .post('/graphql', { query })
        .then((response) => {
          const { data } = response.data;
          if (data) {
            challengeQuestions = data.getOrderedChallengeQuestions;
            challengeData = data.getChallengeQuestion.challenge;
            const {
              allowXpPoints,
              xpPointsDaily,
              xpPointsEnabled,
              xpPointsDisplayEnabled,
            } = data.viewer;

            this.setState({
              allowXpPoints,
              challengeData,
              challengeQuestionId,
              challengeQuestions,
              isLoading: false,
              urlData,
              xpPointsDaily,
              xpPointsDisplayEnabled,
              xpPointsEnabled,
            });
          }
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    }
  };

  processExperienceQuestions = () => {
    const { challengeQuestions } = this.state;

    const challengeQuestionsCopy = [...challengeQuestions];
    const experienceQuestions = challengeQuestionsCopy.filter(
      (challengeQuestion) =>
        challengeQuestion.question.questionType === 'experience'
    );
    return experienceQuestions[0];
  };

  processLearningQuestions = () => {
    const { challengeQuestions } = this.state;
    const challengeQuestionsCopy = [...challengeQuestions];
    const learningQuestions = challengeQuestionsCopy.filter(
      (challengeQuestion) =>
        challengeQuestion.question.questionType === 'learning'
    );
    return learningQuestions;
  };

  processLearningQuestionsLabel = () => {
    let recapLabel;
    const learningQuestions = this.processLearningQuestions();
    const learningQuestionsCompleted = learningQuestions.filter(
      (challengeQuestion) => challengeQuestion.status === 'answered'
    );
    if (learningQuestions.length === learningQuestionsCompleted.length) {
      recapLabel =
        learningQuestionsCompleted.length === 1
          ? I18n.t('challenge_questions.progress_bar.challenge.recap.one')
          : I18n.t('challenge_questions.progress_bar.challenge.recap.all');
    } else if (learningQuestions.length === 1) {
      recapLabel = I18n.t(
        'challenge_questions.progress_bar.challenge.recap.one'
      );
    } else if (learningQuestionsCompleted.length === 0) {
      recapLabel = I18n.t(
        'challenge_questions.progress_bar.challenge.recap.multiple',
        { current: 1, total: learningQuestions.length }
      );
    } else {
      recapLabel = I18n.t(
        'challenge_questions.progress_bar.challenge.recap.multiple',
        {
          current: learningQuestionsCompleted.length + 1,
          total: learningQuestions.length,
        }
      );
    }
    return recapLabel;
  };

  render() {
    const {
      urlData,
      challengeQuestionId,
      isLoading,
      xpPointsDaily,
      allowXpPoints,
      xpPointsEnabled,
      xpPointsDisplayEnabled,
    } = this.state;

    const userInOnboarding = window.location.pathname === '/setup/index';
    const userInChallenge =
      window.location.pathname.split('/')[1] === 'challenge_questions';

    let experienceQuestion;
    let recapLabel;
    if (userInChallenge && !isLoading) {
      experienceQuestion = this.processExperienceQuestions();
      recapLabel = this.processLearningQuestionsLabel();
    }

    return (
      <div className="e-progress-bar">
        {userInOnboarding && (
          <>
            <div className="e-item v-learning v-current">
              {I18n.t('challenge_questions.progress_bar.onboarding.learning')}
            </div>

            <div
              className="e-arrow"
              style={{ backgroundImage: `url(${arrowRight})` }}
            />

            <div className="e-item v-onboarding-experience v-locked">
              {I18n.t(
                'challenge_questions.progress_bar.onboarding.first_challenge'
              )}
            </div>

            <div className="e-item v-onboarding-discovery v-locked">
              {I18n.t('challenge_questions.progress_bar.onboarding.discovery')}
            </div>
          </>
        )}
        {!isLoading && userInChallenge && (
          <>
            {allowXpPoints && xpPointsEnabled && xpPointsDisplayEnabled && (
              <XpPointsCounterHeader points={xpPointsDaily} />
            )}

            {urlData === 'onboarding' && (
              <>
                <div className="e-item v-learning v-answered">
                  {I18n.t(
                    'challenge_questions.progress_bar.onboarding.learning'
                  )}
                </div>
                <div
                  className="e-arrow"
                  style={{ backgroundImage: `url(${arrowRight})` }}
                />
              </>
            )}

            {this.processLearningQuestions().length !== 0 && (
              <>
                <div
                  key={`${experienceQuestion.id}-recaps`}
                  className={`e-item v-learning ${
                    challengeQuestionId !== experienceQuestion.id
                      ? 'v-current'
                      : 'v-answered'
                  }`}
                >
                  {recapLabel}
                </div>
                <div
                  className="e-arrow"
                  style={{ backgroundImage: `url(${arrowRight})` }}
                />
              </>
            )}

            <div
              key={experienceQuestion.id}
              className={`e-item v-experience ${
                challengeQuestionId === experienceQuestion.id
                  ? 'v-current'
                  : experienceQuestion.status === 'answered'
                  ? 'v-answered'
                  : 'v-locked'
              }`}
            >
              {I18n.t('challenge_questions.progress_bar.challenge.challenge')}
            </div>
          </>
        )}
      </div>
    );
  }
}
