import PropTypes from 'prop-types';
import React from 'react';

import I18n from '../../core/i18n';

const icon = require('../../images/icons/xp-points_gravatar.png');

const XpPointsCounterHeader = ({ points }) => (
  <div className="xppoints-counter-header">
    <div
      alt="XP points"
      className="xppoints-counter-header__image"
      style={{ backgroundImage: `url(${icon})` }}
    />

    <div className="xppoints-counter-header__text">
      {I18n.t('challenge_questions.xppoints_header.text')}
    </div>
    <div className="xppoints-counter-header__counter">{points}</div>
  </div>
);

XpPointsCounterHeader.propTypes = {
  points: PropTypes.number.isRequired,
};

export default XpPointsCounterHeader;
