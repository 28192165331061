import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationDE from '../locales/de/translation.json';
import translationEN from '../locales/en/translation.json';
import translationNL from '../locales/nl/translation.json';
import translationFR from '../locales/fr/translation.json';

// for now, window.locale is still set in application.html.erb
// the locale is decided by the backend
// this could possibly change when we have moved everything to the frontend
const lng = window.locale || 'en';

const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  nl: {
    translation: translationNL,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already does this
  },
  lng,
  preload: ['en', 'nl', 'de', 'fr'],
  resources,
});

export default i18n;
