const progressBarQuery = (challengeQuestionId) => `
  query {
    viewer {
      xpPointsDaily
      allowXpPoints
      xpPointsEnabled
      xpPointsDisplayEnabled
    }
    getChallengeQuestion(challengeQuestionId: ${challengeQuestionId}){
        challenge{
            id
            status
        }
    }
    getOrderedChallengeQuestions(challengeQuestionId: ${challengeQuestionId}){
        id
        status
        question{
          questionType
        }
    }
  }
`;

export default progressBarQuery;
